<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <v-btn tile depressed color="grey" width="60" height="60" class="ml-1" @click="$router.go(-1)">
        <v-icon dark>mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn tile depressed color="accent" width="60" height="60" class="ml-1" @click="save">
        <v-icon dark>mdi-content-save</v-icon>
      </v-btn>
    </div>

    <h1 class="py-6">Hozzáférési szint</h1>

    <v-card :loading="loading">
      <v-card-title>
        <v-icon left>mdi-security</v-icon>
        Alapadatok
      </v-card-title>
      <v-card-text>
        <v-row class="m-1">
          <v-col :cols="12" :sm="12" :md="6">
            <v-text-field v-model="form.name" :error-messages="errors.name" label="Megnevezés" filled
              v-mask="$config.inputMasks.name" />
            <v-text-field v-model="form.alias" label="Alias" :disabled="!form.erasable" :error-messages="errors.alias"
              filled v-uppercase />

            <v-autocomplete v-model="form.group_ids" label="Csoportok" :items="groups" item-text="name"
              item-value="element_id" :error-messages="errors.groups" multiple chips hide-details filled />
            <v-switch label="QR kód engedélyezve" :error-messages="errors.barcode_enabled"
              v-model="form.barcode_enabled" />
          </v-col>
          <v-col :cols="12" :sm="12" :md="6">
            <v-textarea label="Megjegyzés" :error-messages="errors.comment" v-model="form.comment" filled :rows="3"
              auto-grow />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mt-6">
      <v-card-title>
        <v-icon left>mdi-chip</v-icon>
        Eszközök
      </v-card-title>
      <v-card-text>
        <v-multi-select-list :headers="[
          { text: 'Eszköz', value: 'device' },
          { text: 'Engedélyezve', value: 'schedule_allow' },
          { text: 'Letiltva', value: 'schedule_deny' },
        ]" :items="elements" v-model="form.elements" item-text="name" item-value="element_id"
          :blank-item-template="{ element_id: 0, schedule_group_id_allow: 0, schedule_group_id_deny: 0 }" unique-values>
          <template #[`item.device`]="{ item, items }">
            <v-autocomplete placeholder="Eszköz" :items="items" item-text="name" item-value="element_id"
              v-model="item.element_id" filled hide-details required :value-comparator="$config.comparators.isEqual" />
          </template>
          <template #[`item.schedule_allow`]="{ item }">
            <v-autocomplete placeholder="Soha" :items="scheduleGroups" item-value="id" item-text="name"
              v-model="item.schedule_group_id_allow" filled hide-details clearable />
          </template>
          <template #[`item.schedule_deny`]="{ item }">
            <v-autocomplete placeholder="Soha" :items="scheduleGroups" item-value="id" item-text="name"
              v-model="item.schedule_group_id_deny" filled hide-details clearable />
          </template>
        </v-multi-select-list>
      </v-card-text>
    </v-card>

    <v-card class="mt-6">
      <v-card-title>
        <v-icon left>mdi-card-account-details-outline</v-icon>
        Kártyák hozzárendelése</v-card-title>
      <v-card-text>
        <v-two-panel-multiselect :headers="[
          { text: 'Kártyaszám', value: 'card_number' },
          { text: 'Birtokos', value: 'owner_name' },
          { text: 'RFID', value: 'rf_id' },
        ]" :search-fields="['card_number', 'owner_name', 'rf_id']" :options="{ sortBy: ['card_number'] }"
          :items="cards" v-model="form.cards" item-value="element_id">
          <template #header="{ search }">
            <v-card outlined class="mb-6">
              <v-card-title>
                <v-icon left>mdi-filter</v-icon>
                Keresés
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col :cols="12" :md="6" :lg="3">
                    <v-text-field label="Kártyatulajdonos" v-model="search.owner_name" hide-details />
                  </v-col>
                  <v-col :cols="12" :md="6" :lg="3">
                    <v-text-field label="Kártyaszám" v-model="search.card_number" hide-details />
                  </v-col>
                  <v-col :cols="12" :md="6" :lg="3">
                    <v-text-field label="RFID" v-model="search.rf_id" hide-details />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
        </v-two-panel-multiselect>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        name: '',
        alias: '',
        group_ids: [],
        barcode_enabled: {
          value: this.selected ? 0 : 1,
          selected: false,
        },
        comment: '',
        elements: [],
      },
      cards: [],
      groups: [],
      elements: [],
      scheduleGroups: [],
      errors: {},
      loading: false,
    };
  },

  mounted() {
    this.fetchAccessLevel();
  },

  methods: {
    async fetchAccessLevel() {
      this.loading = true;
      try {
        const response = await this.$http.get(
          `access-levels/details/${this.$route.params.id || 0}`
        );
        this.form = response.access_level;
        this.groups = response.groups;
        this.fetchScheduleGroups();
        this.fetchCards();
        this.fetchGroups();
        this.fetchElements();
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async fetchGroups() {
      try {
        const response = await this.$http.post('groups/list');
        this.groups = response.groups;
      } catch (e) {
        console.error(e);
      }
    },

    async fetchElements() {
      try {
        const response = await this.$http.post('elements/list', {
          type_codes: ['AJTO', 'SOROMPO', 'FORGOVILLA'],
          limit: 1000
        });
        this.elements = [...this.elements, ...response.elements];
      } catch (e) {
        console.error(e);
      }
    },

    async fetchCards() {
      try {
        const limit = (await this.$http.get('cards/list')).cards_count;
        const response = await this.$http.post('cards/list', {
          limit
        });
        this.cards = [...this.cards, ...response.cards];
      } catch (e) {
        console.error(e);
      }
    },

    async fetchScheduleGroups() {
      try {
        const response = await this.$http.post('schedule-groups/list');
        this.scheduleGroups = [...this.scheduleGroups, ...response.schedule_groups];
      } catch (e) {
        console.error(e);
      }
    },

    async save() {
      this.loading = true;
      this.errors = {};
      try {
        const response = await this.$http.post('access-levels/save', this.form);

        if (this.lodash.size(response.errors) > 0) {
          this.errors = response.errors;
          return;
        }

        if (response.status === 'OK') {
          this.$dialog.notify.success('Sikeres mentés');
          if (this.$route.name === 'NewAccessLevel') {
            this.$router.push({
              name: 'AccessLevelEditor',
              params: { id: response.card.element_id },
            });
          } else {
            this.fetchCard();
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
